import React from "react"
import Image from '../components/image-provider'

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => {
    
    const Heading = ({ title, className }) => (
        <h2 className={`${className} text-base opacity-70 px-4 mt-4`}>{title}</h2>
    )

    const Paragraph = ({ text, className }) => (
        <p className={`${className} text-sm px-4 mt-2`}>{text}</p>
    )

    const BulletSection = ({ title, text, className }) => (
        <ul className='text-sm pl-8 pr-4 closed-disc'>
            <li className={className}>{title}
                <ul className="open-disc">
                    <li>{text}</li>
                </ul>
            </li>
        </ul>
    )
  
    return(
      <Layout>
          <SEO
            title="Privacy"
        />
          <Image
            fileName="rarechicagoprivacybanner.jpg"
            className="laptop:h-screen laptop-min-height"
            alt="Photograph of dozens of security cameras pointed at two individuals on a street. Text `You're sensitive about your privacy. We are too.` overlayed on image."
        />
          <div className="w-full bg-gandalfgray pb-8">
              <div className="max-viewport mx-auto pt-4">
                  <Heading title="As of January 6th, 2021" />
                  <Paragraph
                      className="mt-6"
                      text="This Privacy Policy governs the manner in which Rare Chicago collects, uses, maintains and discloses information collected from users (each, a “User”) of the https://www.rarechicago.com website (“Site”). This privacy policy applies to the Site and all products and services offered by Rare Chicago. By accessing, visiting or using the Service, each User expressly consents to our collection, storage, use and disclosure of the User’s information as described in this Privacy Policy. Please note that Users that disagree with anything in this Privacy Policy must not use the Service."
                  />
                  <Heading title="information collected" className="uppercase text-matteblue" />
                  <Heading title="A. Information directly and voluntarily provided to us." />
                  <Paragraph
                      text="If a User visits the Site, we may collect information the User provides to us when the User communicates with any of our departments such as customer service or technical services. If a User submits an inquiry to us regarding the Service through the Site, the User will be required to provide the User’s first and last name and email address. Users that sign up for our email mailing list will be required to provide their email address. Users may refuse to supply their information, however, it may prevent them from using certain features of the Site or Service."
                  />
                  <Heading title="B. Information automatically collected through the site." />
                  <Paragraph
                      text="We may automatically collect information about Users when they use the Site. For example, when Users access the Site through a computer, we will automatically collect information such as a User’s browser type and version, computer and connection information, IP address and standard web log information."
                  />
                  <Paragraph
                      text="We may automatically collect information from Users when they use the Site through the use of “cookies” and other similar technologies, such as web beacons. Cookies are small amounts of data that are stored within a computer’s Internet browser and are accessed and recorded by websites so that they can recognize the same browser navigating online at a later time. Information that may be collected by cookies when Users use the Site may include, without limitation: pages visited within the Site; the date and time of a visit to the Site; the amount of time spent using the Site; the Internet Protocol (IP) address used to connect a User’s computer to the Internet; and/or computer and connection information such as a User’s browser type and version, operating system and platform."
                  />
                  <p className="text-sm px-4 mt-2">
                      Users can set their browser to reject cookies or to notify a User when a cookie is sent. However, Users that disable cookies may not be able to use certain features of the Site. To find out more about cookies, including how to see what cookies have been set on a device and how to manage and delete them, visit
                      <a href="https://www.allaboutcookies.org/" className="text-sm text-magenta"> www.allaboutcookies.org</a>
                      .
                  </p>
                  <Heading title=" C. Information shared on third party websites or through social media services." />
                  <Paragraph
                      text="The Site may include links to third party websites and social media services where Users will be able to post comments, stories, reviews or other information. Use of these third party websites and social media services may result in the collection or sharing of information by these third party websites and social media services. The Company does not have access to or control over these third parties or the cookies, web beacons or other technology that these third parties may use. We are not responsible for the privacy practices of these third parties or the content on any third party website. We encourage Users to review the privacy policies and settings on the third party websites and social media services with which they interact to make sure they understand the information that may be collected, used, and shared by those third party websites and social media services."
                  />
                  <Heading title="D. Information collected by third party analytics Services." />
                  <Paragraph
                      text="We may work with third party analytics services to help us understand how the Site is being used, such as tracking the frequency and duration of use of the Site. These analytics services may use cookies to collect information about the content Users view, what websites Users visit immediately prior to and after visiting the Site, and Users’ system information and geographic information. The information generated by these cookies about Users’ use of the Site will be transmitted to and stored by the applicable analytics services. The information collected by these analytics services allows us to analyze Users’ use of the Site"
                  />
                  <Heading title="E. Information provided to payment processors." />
                  <Paragraph
                      text="All payments made through the Service are processed by our third party providers. All information collected by these third party providers for purposes of processing payments is not available to us, unless a User has otherwise provided this information to us in connection with use of the Service."
                  />
                  <Heading title="F. Information prohibited." />
                  <Paragraph
                      text="Processing of personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation shall be prohibited."
                  />
                  <Heading title="How we use the information we collect" />
                  <BulletSection
                      className="mt-4"
                      title="To run and operate our Site"
                      text="We may need your information to display content on the Site correctly."
                  />
                  <BulletSection
                      title="To improve customer service"
                      text="Information you provide helps us respond to your customer service requests and support needs more efficiently."
                  />
                  <BulletSection
                      title="To personalize the user experience"
                      text="We may use information in the aggregate to understand how our Users as a group use the services and resources provided through the Service."
                  />
                  <BulletSection
                      title="To improve the service"
                      text="We may use feedback you provide to improve the Site, Service and other potential products and services that we may offer."
                  />
                  <BulletSection
                      title="To process payments"
                      text="We may use the information Users provide about themselves when placing an order only to process payments in connection with the applicable order."
                  />
                  <BulletSection
                      title="To send periodic emails"
                      text="We may use Users’ email addresses to send Users information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. Users that opt-in to our mailing list will receive emails that may include company news, updates, related product or service information, etc. In addition, we may email Users about products and services that we believe may be of interest to them. If Users wish to opt-out of receiving these marketing emails from us, Users can follow the instructions contained in the applicable email which will allow Users to opt-out of receiving further email marketing emails from us."
                  />
                  <Heading title="Contacting us" />
                  <p className="text-sm px-4 mt-2">
                      If you have any questions about this Privacy Policy or require assistance related to the Services or Site, please contact us at
                      <a href="mailto: hello@rarechicago.com" className="text-sm text-magenta"> hello@rarechicago.com</a>
                      .
                  </p>
              </div>
          </div>
      </Layout>
    )
}

export default PrivacyPage;
